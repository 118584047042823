import { ReactChild, useEffect } from 'react';
import { Route, Routes } from "react-router-dom";
import { Landing } from './pages/landing/Landing';
import { NewPayment } from './pages/payment/NewPayment';
import { TimeExpired } from './pages/time-expired/TimeExpired';
import { MockTheme } from './utils/MockTheme';
import { PaymentLayout } from './pages/payment-layout/PaymentLayout';
import { Refund } from './pages/payment/Refund';
import { Underpaid } from './pages/payment/Underpaid';
import { PartialPayment } from './pages/payment/PartialPayment';
import "vanilla-cookieconsent/dist/cookieconsent.css";
import * as CookieConsent from "vanilla-cookieconsent";

function App() {

  useEffect(() => {
    CookieConsent.run({
      categories: {
        necessary: {
          enabled: true, 
          readOnly: true
        },
        analytics: {
          readOnly: false,
          autoClear: {
            cookies: [
              {
                name: /^(_ga)/
              },
              {
                name: '_gid'
              }
            ]
          }
        }
      },
      language: {
        default: 'en',
        translations: {
          en: {
            consentModal: {
              title: 'We use cookies',
              description: 'We use cookies on our websites to enhance your experience, analyze traffic, and for security and marketing.',
              acceptAllBtn: 'Accept all',
              showPreferencesBtn: 'Cookie Settings'
            },
            preferencesModal: {
              title: 'Manage cookie preferences',
              acceptAllBtn: 'Accept all',
              savePreferencesBtn: 'Accept current selection',
              closeIconLabel: 'Close modal',
              sections: [
                {
                  title: 'Cookies necessary for the correct operation of the site are always enabled. Other cookies are configurable.'
                },
                {
                  title: 'Essential cookies',
                  description: 'These cookies are essential so that you can use the website and use its functions. They cannot be turned off. They are set in response to requests made by you, such as setting your privacy preferences, logging in or filling in forms.',
                  linkedCategory: 'necessary'
                },
                {
                  title: 'Performance cookies',
                  description: 'Performance cookies are used in the tracking of how you use a website and app during your visit, without collecting personal information about you. Typically, this information is anonymous and aggregated with information tracked across all site users, to help companies understand visitor usage patterns, identify and diagnose problems or errors their users may encounter, and make better strategic decisions in improving their overall website experience. These cookies may be set by the website you are visiting (first-party) or by third-party services. We use performance cookies on our site and app.',
                  linkedCategory: 'analytics'
                },
              ]
            }
          }
        }
      },
      guiOptions: {
        consentModal: {
          layout: 'box',
          position: 'bottom center'
        },
        preferencesModal: {
          layout: 'cloud',
          position: 'center',
          equalWeightButtons: false,
          flipButtons: true
        }
      }
    });
  }, []);
  return (
    <MockTheme>
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route element={<PaymentLayout />}>
          <Route path="/new-payment" element={<NewPayment />} />
          <Route path="/payment-detected" element={<NewPayment />} />
          <Route path="/payment-success" element={<NewPayment />} />
          <Route path="/expired" element={<NewPayment />} />
          <Route path="/refund" element={<Refund />} />
          <Route path="/payment-overpaid" element={<NewPayment />} />
          <Route path="/payment-underpaid" element={<Underpaid />} />
          <Route path="/partial-payment" element={<PartialPayment />} />
        </Route>
      </Routes>
    </MockTheme>
  );
}

export default App;
